






















import { Component, Vue } from "vue-property-decorator";
import VideoComponent from "@/components/shared/VideoComponent.vue";

@Component({
    components: {
        VideoComponent
    }
})
export default class LiveStreamVideoWithComments extends Vue {
    get sectionToUse() {
        const res = this.$store.getters.getPageOptions("StreamVideoLive");

        return res.components;
    }
}
